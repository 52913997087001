.hairsindia-enquire-container {
  background-image: url(../../assets/images/homepage/enquire/bg3.png);
  width: 100%;
  height: 750px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  align-items: center;
  justify-content: center;
}
.hairsindia-homepage-banner-image {
  width: 100%;
  object-fit: cover;
  display: block;
}
.hairsindia-homepage-inner-container {
  position: relative;
}
.hairsindia-homepage-container {
  background-image: url(../../assets//images/homepage/home_page_banner.jpeg);
  background-position: center;
  background-repeat: no-repeat;
  width: 100%;
  height: auto;
}
.hairsindia-homepage-banner-wrapper::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.33);
  pointer-events: none;
}
.hairsindia-india-image {
  max-width: 750px;
  height: auto;
  opacity: 0.9;
  position: absolute;
  left: 0;
  right: 0;
  margin: auto;
  bottom: -80px;
  width: 100%;
  padding: 0 20px;
  animation: zoom 2.5s ease-in-out infinite;
  overflow: hidden;
  z-index: 3;
}
@keyframes zoom {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.03);
  }
  100% {
    transform: scale(1);
  }
}

/* owner */
.hairsindia-owner-container .owner-bg {
  width: 100%;
  height: auto;
  position: relative;
}
.hairsindia-owner-container .owner-m-bg {
  display: none;
}
.hairsindia-owner-name {
  font-family: "Avantgarde Md", sans-serif;
  font-size: 48px;
  font-weight: 500;
  color: white;
  transform: scaleY(1.1);
  text-shadow: 3px -2px 2px gray;
  line-height: 44px;
}
.hairsindia-owner-position {
  font-family: "Avantgarde Md", sans-serif;
  font-size: 30px;
  font-weight: 600;
  line-height: 30px;
}
.hairsindia-name-content {
  width: fit-content;
  background-color: #84c136;
  padding: 20px 120px 20px 70px;
  position: absolute;
}
.hairsindia-name-content:before {
  content: "";
  position: absolute;
  width: 30px;
  height: 100%;
  background-color: #ce6736;
  top: 0;
  left: 0;
}
.hairsindia-bleached-big-image {
  width: 500px;
  height: 520px;
  background-color: #6bb40e;
  min-width: 200px;
  /* width: 100%; */
  object-fit: cover;
  border: 7px solid #033703;
}
.hairsindia-enquire-text-image {
  height: 100%;
  gap: 70px;
}
.hairsindia-enquire-text-image2 {
  width: 100%;
  max-height: 780px;
  object-fit: cover;
}
.chi-enquire-text-cntr2 {
  display: none;
}
.hairsindia-enquire-section {
  font-family: var(--font-family1);
  z-index: 222;
  width: 650px;
  border-radius: 40px;
  background-color: #fff !important;
  position: absolute;
  padding: 35px 50px;
  opacity: 0.7;
}
.hairsindia-enquire-address {
  padding-bottom: 10px;
  border-bottom: 2px dashed #3a6108;
}
.hairsindia-enquire-address img {
  width: 20px;
  height: 30px;
  object-fit: cover;
}
.hairsindia-enquire-contact,
.hairsindia-enquire-mail {
  border-bottom: 2px dashed #3a6108;
}
.hairsindia-enquire-contact img {
  width: 26px;
  height: 26px;
  object-fit: cover;
}
.hairsindia-enquire-mail img {
  width: 26px;
  height: 20px;
  object-fit: cover;
}
.hairsindia-enquire-web > div > img {
  width: 28px;
  height: 28px;
  object-fit: cover;
}
.hairsindia-enquire-chat {
  width: 125px;
  height: auto;
  object-fit: cover;
  position: absolute;
  right: 0;
  bottom: -30px;
}
.hairsindia-homepage-banner {
  display: none;
}
.hairsindia-enquire-text-image3 {
  width: 130px;
  height: auto;
  position: absolute;
  right: 40px;
  top: 20px;
}

@media screen and (max-width: 1200px) {
  .hairsindia-enquire-container {
    justify-content: flex-end;
    padding-right: 20px;
  }
}
@media screen and (max-width: 1023px) {
  .hairsindia-name-content {
    padding-right: 100px;
  }
  .hairsindia-enquire-text-image3 {
    width: 100px;
  }
  .hairsindia-enquire-section {
    max-width: 550px;
    width: 100%;
    margin: 0 20px 0 183px;
    padding: 25px;
  }
  .hairsindia-enquire-text-image2 {
    display: none;
  }
  .chi-enquire-text-cntr {
    width: 90%;
    height: 100%;
  }
}

@media screen and (max-width: 768px) {
  .big-image-container {
    width: 90%;
    height: auto;
    border: 3px dashed white;
    padding: 4px;
  }
  .hairsindia-owner-container .owner-bg {
    display: none;
  }
  .hairsindia-owner-container .owner-m-bg {
    display: flex;
  }
  .hairsindia-owner-name {
    font-size: 40px;
  }
  .hairsindia-owner-position {
    font-size: 25px;
  }
  .hairsindia-name-content {
    padding: 2% 5% 2% 8%;
  }
  .sections-divider {
    height: 40px;
  }
  .hairsindia-homepage-banner-image {
    display: none;
  }
  .hairsindia-homepage-banner {
    display: block;
  }
  .hairsindia-enquire-text-image {
    height: fit-content;
  }
  .hairsindia-enquire-text-image3 {
    width: 75px;
  }
  .hairsindia-enquire-section {
    width: 90%;
    margin: 0;
    padding: 20px;
  }
  .hairsindia-enquire-chat {
    width: 70px;
  }
  .hairsindia-enquire-container {
    height: 550px;
    justify-content: center;
    padding: 20px;
  }
  .chi-enquire-text-cntr2 {
    width: 42%;
    height: fit-content;
    display: inline-block;
    background-color: #183005;
    padding: 4px 16px;
    margin: 30px 20px;
  }
  .chi-enquire-text-cntr {
    display: none;
  }
  .hairsindia-india-image {
    bottom: -40px;
  }
}

@media screen and (max-width: 480px) {
  .hairsindia-owner-name {
    font-size: 26px;
    line-height: 28px;
  }
  .hairsindia-owner-container .owner-bg {
    min-height: 220px;
  }
  .hairsindia-owner-position {
    font-size: 18px;
    line-height: 18px;
  }
  .hairsindia-name-content {
    padding: 10px 18px 10px 25px;
  }
  .hairsindia-enquire-text-image3 {
    right: 25px;
  }
  .hairsindia-india-image {
    max-width: 300px !important;
  }
  .hairsindia-name-content:before {
    width: 18px;
  }
}
