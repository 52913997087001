@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");

* {
  margin: 0;
  padding: 0;
}

li,
ul,
ol {
  list-style-type: none;
  margin: 0;
}
h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
}
:focus {
  outline: none;
}
button {
  cursor: pointer;
  background-color: transparent;
  border: none;
}

@font-face {
  font-family: "Itc Avantgarde", sans-serif;
  src: url(../font-family/itc-avant-grade/ITCAvantGardeStd-Bold.ttf);
}
@font-face {
  font-family: "Georgia", sans-serif;
  src: url(../font-family/Georgia-Font/Georgia.ttf);
}
@font-face {
  font-family: "Gawker", sans-serif;
src:url(../font-family/gawkers/GAWKER-Regular.ttf);
}
@font-face {
  font-family: "thirsty", sans-serif;
  src: url(../font-family/thirsty_script/ThirstyScriptExtraBoldDemo.otf);
}

:root {
  --font-family1: "Itc Avantgarde", sans-serif;
  --font-family2: "Georgia", sans-serif;
  --font-family3: "Gawker", sans-serif;
  --font-family4: "Thirsty", sans-serif;
}
