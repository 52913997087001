.hairsindia-home-about-section > .about-banner-image {
  width: 100%;
  height: 750px;
}
.about-banner-image-mobile {
  width: 100%;
  height: 600px;
}
.foundation-card-inner-container {
  width: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  z-index: 0;
}
.foundation-card-prev > svg {
  transform: rotate(180deg);
}
.foundation-card-container-title {
  color: var(--Black---2);
  font-family: var(--font-primary-family);
  font-size: var(--font-heading6);
  font-weight: 600;
  line-height: var(--line-height-heading6);
}
.foundation-card {
  width: 230px !important;
  object-fit: cover;
}
.foundation-card-image-container {
  display: flex;
  flex-direction: column;
  gap: 10px;
}
.foundation-card-image-container > img {
  width: 100%;
  height: 82px;
  object-fit: cover;
}
.foundation-card-image-container > .foundation-card-program-text {
  color: var(--Black---2);
  font-family: var(--font-primary-family);
  font-size: var(--font-medium-size);
  font-weight: 600;
  line-height: var(--line-height-large);
}
.foundation-card-program-exercise-container {
  display: flex;
  align-items: center;
  gap: 8px;
  margin-top: 4px;
}
.foundation-card-program-exercise-container > span {
  color: var(--Black---5);
  font-family: var(--font-primary-family);
  font-size: var(--font-small-size);

  font-weight: 400;
  line-height: var(--line-height-normal);
}
.foundation-card-btn-container {
  width: 100%;
  height: 40px;
  border: 0.5px solid var(--Blue---1);
  border-radius: 4px;
}
.hairsindia-home-about-section-mobile
  .swiper.swiper-initialized.swiper-horizontal.swiper-backface-hidden {
  padding: 0 50px;
}
.hairsindia-home-about-section-mobile
  .swiper.swiper-initialized
  .swiper-wrapper
  .swiper-slide {
  width: 250px !important;
  display: flex;
  justify-content: center;
  align-items: center;
}
.hairsindia-home-about-section-mobile {
  background-image: url(../../assets/images/homepage/aboutpage/bgm.png);
  background-position: center;
  background-repeat: no-repeat;
  width: 100%;
  height: 500px;
  background-size: cover;
  display: flex;
  align-items: center;
  position: relative;
}
.hairsindia-about-logo-mobile {
  position: absolute;
  z-index: 2;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding: 10px 20px;
  display: none;
}
.hairsindia-prod-nat-mobile {
  max-width: 80px;
  height: auto;
}
.hairsindia-prod-nat-mobile {
  max-width: 100px;
  height: auto;
}
.hairsindia-about-wwr {
  max-width: 120px;
  height: auto;
}
.abt-mask-image-one,
.abt-mask-image-two,
.abt-mask-image-three,
.abt-mask-image-four,
.abt-mask-image-five,
.abt-mask-image-six,
.abt-mask-image-seven,
.abt-mask-image-eight {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: auto;
  opacity: 0;
  transition: all 0.3s linear;
}
.hairsindia-about-image-one,
.hairsindia-about-image-two,
.hairsindia-about-image-three,
.hairsindia-about-image-four,
.hairsindia-about-image-five,
.hairsindia-about-image-six,
.hairsindia-about-image-seven,
.hairsindia-about-image-eight {
  position: relative;
  z-index: 1;
  transition: all 1s ease-in-out;
}
.about-image-container-one:hover .abt-mask-image-one,
.about-image-container-two:hover .abt-mask-image-two,
.about-image-container-three:hover .abt-mask-image-three,
.about-image-container-four:hover .abt-mask-image-four,
.about-image-container-five:hover .abt-mask-image-five,
.about-image-container-six:hover .abt-mask-image-six,
.about-image-container-seven:hover .abt-mask-image-seven,
.about-image-container-eight:hover .abt-mask-image-eight {
  opacity: 1;
}
.hairsindia-about-image-one:hover,
.hairsindia-about-image-five:hover {
  transform: scale(1.15);
  transition: all 1s ease-in-out;
}

@keyframes wiggle {
  0% {
    transform: rotate(0deg);
  }
  25% {
    transform: rotate(4deg);
  }
  50% {
    transform: rotate(-4deg);
  }
  75% {
    transform: rotate(4deg);
  }
  100% {
    transform: rotate(0deg);
  }
}

.wiggle:hover {
  display: inline-block;
  animation: wiggle 0.5s !important;
  transition-delay: 0ms;
}

.hairsindia-about-image-three:hover,
.hairsindia-about-image-four:hover,
.hairsindia-about-image-six:hover,
.hairsindia-about-image-seven:hover,
.hairsindia-about-image-eight:hover {
  animation-name: wiggle;
  animation-duration: 1s;
  animation-iteration-count: 1;
  animation-timing-function: ease-out;
}

@media screen and (max-width: 1280px) {
  .hairsindia-india-image {
    max-width: 550px;
  }
  .hairsindia-home-about-section {
    display: none;
  }
  .hairsindia-home-about-section-mobile
    .swiper.swiper-initialized.swiper-horizontal.swiper-backface-hidden {
    transform: translateY(18%);
    padding: 30px 0;
  }
  .hairsindia-about-logo-mobile {
    display: flex;
  }
}
@media screen and (min-width: 1280px) {
  .hairsindia-home-about-section-mobile {
    display: none;
  }
  .foundation-card {
    height: auto;
  }
}

@media screen and (max-width: 1023px) {
  .hairsindia-india-image {
    max-width: 500px;
  }
  .foundation-card-prev,
  .foundation-card-next {
    display: none;
    z-index: -1;
  }
  .foundation-card-main-container {
    padding: 20px 0 20px 20px;
  }
}

@media screen and (max-width: 768px) {
  .hairsindia-india-image {
    max-width: 350px;
  }
  .about-banner-image-mobile {
    height: 400px;
  }
  .hairsindia-home-about-section-mobile
    .swiper.swiper-initialized
    .swiper-wrapper
    .swiper-slide.foundation-card {
    width: 160px !important;
    height: 160px !important;
  }
  .hairsindia-home-about-section-mobile {
    height: 400px;
  }
  .hairsindia-prod-nat-mobile {
    max-width: 80px;
  }
  .hairsindia-about-wwr {
    max-width: 100px;
  }
}

@media screen and (max-width: 480px) {
  .hairsindia-about-logo-mobile {
    padding: 30px 20px;
  }
}
